import React, { useEffect, useState } from "react";
import { cn } from "../lib/helpers";

import PortableText from "./portableText";

import * as styles from "./otherMain.module.css";
import * as commonStyles from "../styles/common.module.css";

function AboutMain({ blocks }) {
  return (
    <div className={cn(styles.container, commonStyles.fontButler, commonStyles.colorDarkGray)}>
      <PortableText blocks={blocks} />
    </div>
  );
}

export default AboutMain;
