import React from "react";

import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import PageContainer from "../components/pageContainer";
import OtherMain from "../components/otherMain";

const Other = (props) => {
  const { data, errors, seo, dataKey } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const magazinPreview = data && data?.magazinPreview?.nodes;
  const allServices = data && data?.allServices?.nodes;
  const blocksData = data && data?.blocks[dataKey];
  const tags = data && data?.tags?.nodes;

  return (
    <Layout services={allServices} tags={tags} magazin={magazinPreview}>
      <SEO title={seo.title} description={seo.description} keywords={[]} />
      <PageContainer>
        <OtherMain blocks={blocksData} />
      </PageContainer>
    </Layout>
  );
};

export default Other;
